import React, { useState, useRef, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import "../../../styles/landing_page/landingpage.css";

const Counter = () => {
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    setStartCounting(true);
  }, []);

  const formatNumber = useCallback((num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}k +`;
    }
    return num;
  }, []);

  return (
    <>
      <section className="counter_main">
        <div className="counter_container">
          <div className="counter_row">
            <div className="counter_value row ">
              <div className="counter-inner col-6 col-md-3">
                {startCounting && (
                  <CountUp
                    end={50000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>Job Vacancy</h6>
              </div>
              <div className="counter-inner  col-6  col-md-3">
                {startCounting && (
                  <CountUp
                    end={20000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>People Got Hired</h6>
              </div>
              <div className="counter-inner pt-2 pt-md-0 col-6 col-md-3">
                {startCounting && (
                  <CountUp
                    end={2000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>Companies</h6>
              </div>
              <div className="counter-inner pt-2 pt-md-0 col-6 col-md-3">
                {startCounting && (
                  <CountUp
                    end={10000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>Jobs Posted</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;