import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/landing_page/lateralhiring.css";
import idfc from "../../../utils/auth/images/idfcdlogo.png";
import kotak from "../../../utils/auth/images/kotak.png";
import csb from "../../../utils/auth/images/csb.jpeg";
import bajajfinance from "../../../utils/auth/images/bajajfinance.png";
import bajajserve from "../../../utils/auth/images/bajajserve.jpg";
import leftArrowIcon from "../../../utils/landingpage/images/leftarrowwhiteicon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import capitalFloat from "../../../utils/auth/images/capital-float.jpg";
import tatacapital from "../../../utils/auth/images/tatacapital.png";
import StandardCharteredbank from "../../../utils/auth/images/scbank.png";
import infograins from "../../../utils/auth/images/infograinsorang.svg";
import db from "../../../utils/auth/images/Deutsche-Bank.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

const testimonials = [
  // {
  //   name: "Bajaj finserv limited",
  //   title: "Web Designer",
  //   text: "",
  //   image: bajajfinance,
  // },
  // {
  //   name: "Bajaj finance limited",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: bajajserve,
  // },
  // {
  //   name: "Bajaj Housing Finance Limited",
  //   title: "Software Engineer",
  //   text: "",
  //   image: bajajfinance,
  // },
  // {
  //   name: "Kotak Mahindra Finance Limited",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: kotak,
  // },
  // {
  //   name: "IDFC Bank Limited",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: idfc,
  // },
  // {
  //   name: "CSB Bank Limited",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: csb,
  // },
  // {
  //   name: "Capital Float",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: capitalFloat,
  // },
  // {
  //   name: "Tata Capital",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: tatacapital,
  // },
  // {
  //   name: "Standard Chartered Bank",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: StandardCharteredbank,
  // },
  // {
  //   name: "Deautche Bank PLC.",
  //   title: "UI/UX Designer",
  //   text: "",
  //   image: db,
  // },
  {
    name: "Infograins Software Solutions.",
    title: "UI/UX Designer",
    text: "",
    image: infograins,
  },
  {
    name: "Infograins Software Solutions.",
    title: "UI/UX Designer",
    text: "",
    image: infograins,
  },
  {
    name: "Infograins Software Solutions.",
    title: "UI/UX Designer",
    text: "",
    image: infograins,
  },
];

const CustomArrow = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`custom-arrow ${
      direction === "prev" ? "prev-arrow" : "next-arrow"
    }`}
  >
    <img
      src={direction === "prev" ? leftArrowIcon : rightIconArrow}
      alt={direction === "prev" ? "Previous" : "Next"}
      style={{ height: "31px" }}
    />
  </button>
);

const ThinkAbout = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, // Set the speed to 2 seconds (2000ms)
    arrows: false, // Disable default arrows
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <section className="think-about-main">
      <div className="think-about-container">
        <div className="think-about-content">
          <div className="think-about-content-wrapper">
            <div className="think-about-content-heading">
              <span className="think-about-content-heading-new-text">
                If we speak about clients, we have catered in the banking
                industry.
              </span>
              <br />
              <span className="think-about-content-heading-new-text-font">
                A few large and midsize banks like Kotak Mahindra Bank, IDFC
                Bank, RBL Bank, Standard Chartered Bank, Deutsche Bank, CSB
                Bank, and many more. In NBFC and HFC we have catered TATA
                capital, Bajaj Finserv, Bajaj Finance, Bajaj Housing Finance,
                Aditya Birla Capital, Aadhar Housing, Loan Tap Financial
                Technologies, Satin Finserve, etc. Including some IT and non-IT
                clients Named Grant Thornton GT Bharat, Indegene, IQVIA, and a
                lot of midsize firms. We have catered to all clients at
                pan-Indian level, hiring across the vertical’s not early sales
                and business development but HO-level roles like product’s
                strategy, finance account, collections etc.
              </span>
            </div>
            <div className="think-about-navigation">
              <CustomArrow direction="prev" onClick={goToPrev} />
              <CustomArrow direction="next" onClick={goToNext} />
            </div>
          </div>
        </div>
        <div className="think-about-slider-section">
          <div className="think-about-slider-container">
            <Slider ref={sliderRef} {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className="think-about-box-parent" key={index}>
                  <div className="think-about-box">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="think-about-img"
                    />
                    <h3 className="think-about-name">{testimonial.name}</h3>
                    {/* <p className="think-about-title">{testimonial.title}</p> */}
                    <p className="think-about-text">{testimonial.text}</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThinkAbout;
